import React, {useEffect, useState} from 'react'
import {ImageFormatSupportContext} from '../context/ImageFormatSupportContext'


const ImageFormatSupportProvider = ({children}) => {

    const [formats, setFormats] = useState([]) // ['avif', 'webp', 'jpg']

    useEffect(() => {

        const checkImageFormatSupport = (dataURI) => {
            return new Promise((resolve) => {
                const image = new Image()
                image.src = dataURI
                image.onload = image.onerror = () => {
                    resolve(image.width > 0 && image.height > 0)
                }
            })
        }

        const avifDataURI = 'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A='
        const webpDataURI = 'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA'

        // const startTime = performance.now()

        Promise.all([
            checkImageFormatSupport(avifDataURI),
            checkImageFormatSupport(webpDataURI)
        ]).then(([isAvifSupport, isWebpSupport]) => {

            // const endTime = performance.now()
            // const duration = endTime - startTime
            // console.log(`Проверка форматов заняла ${duration.toFixed(2)} мс`)

            setFormats([
                isAvifSupport && 'avif',
                isWebpSupport && 'webp',
                'jpg'
            ].filter(Boolean))
        })

    }, [])

    return (
        <ImageFormatSupportContext.Provider value={formats}>
            {children}
        </ImageFormatSupportContext.Provider>
    )
}

export default React.memo(ImageFormatSupportProvider)
