import {createSlice} from '@reduxjs/toolkit'


const placesFilterBtnSlice = createSlice({
    name: 'placesFilterBtn',
    initialState: {
        showMenu: false,
        showBtn: false
    },
    reducers: {
        showMenuHandler: (state, action) => {
            state.showMenu = true
        },
        hideMenuHandler: (state, action) => {
            state.showMenu = false
        },
        showBtnHandler: (state, action) => {
            state.showBtn = true
        },
        hideBtnHandler: (state, action) => {
            state.showBtn = false
        }
    }
})

export const {showMenuHandler, hideMenuHandler, showBtnHandler, hideBtnHandler} = placesFilterBtnSlice.actions

export default placesFilterBtnSlice.reducer
